import React from 'react';
import ReactDOM from 'react-dom';

// import $ from "jquery";

class WithBounds extends React.Component {

    constructor(props) {
        super(props);
        // this.$window = $(window);
        this.state = {
            outOfBounds: false,
        };
    }

    update() {
        const element = ReactDOM.findDOMNode(this);

        let newState = {left: null, top: null, width: null, height: null};

        if (element) {
            const bounds = element.getBoundingClientRect();
            newState     = {
                left:   bounds.left,
                top:    bounds.top,
                width:  bounds.width,
                height: bounds.height,
            };
            if (bounds.bottom < 0) {
                if (this.state.outOfBounds) return;
                newState.outOfBounds = true;
            }
        }

        if (newState.top !== null || this.state.top !== null)
            requestAnimationFrame(() => this.setState(newState));
    }

    componentDidMount() {
        this.update();
        this.listener = () => this.update();
        window.addEventListener('resize', this.listener);
        document.addEventListener('scroll', this.listener);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.listener);
        document.removeEventListener('scroll', this.listener);
    }

    render() {
        return this.props.children({
            update: this.update.bind(this),
            left:   this.state.left,
            top:    this.state.top,
            width:  this.state.width,
            height: this.state.height,
        });
    }
}

const ParallaxLayer = ({

    amt: parallaxAmt = 0,
    style = {},
    abs = false,

    ...props,

} = {}) => <WithBounds>{({top}) => {

    const offset = Math.round(-top * parallaxAmt);

    const styles = {
        height: '100%',
    };

    return <div style={{
        ...styles,
        ...style,
        ...abs ? {
            position: 'absolute',
            width:    '100%',
            height:   '100%',
        } : {},
        overflow: 'hidden',
    }}>
        <div style={{
            ...styles,
            transform: `translate3d(0,${offset}px,0)`,
        }} {...props}/>
    </div>;

}}</WithBounds>;

export const FixedParallaxLayer = ({style = {}, ...props}) => <div style={{
    width:    '100%',
    height:   '100%',
    overflow: 'hidden',
    ...style,
}}>
    <div style={{
        position: 'fixed',
        top:      0,
        left:     0,
        width:    '100%',
        height:   '100%',
    }} {...props}/>
</div>;

export default ParallaxLayer;