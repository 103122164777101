export default [{
    "ID":          12,
    "Client":      "2WEI Music",
    "YouTube URL": "https://www.youtube.com/watch?v=lYWsoJSt2Uw",
    "Title":       "OTTO - Weihnachten ist alles drin"
}, {
    "ID":          9,
    "YouTube URL": "https://www.youtube.com/watch?v=2VlODJlyeE0",
    "Title":       "Covergirl - Girls Who Code"
}, {
    "ID":          6,
    "Client":      "2WEI Music",
    "YouTube URL": "https://www.youtube.com/watch?v=qYULxCZhEKE",
    "Title":       "Burger King - Lieferservice"
}, {
    "ID":          5,
    "Client":      "2WEI Music",
    "YouTube URL": "https://www.youtube.com/watch?v=Nt9eBom5ZoE",
    "Title":       "Sky - Winter Wonderland"
}, {
    "ID":          2,
    "Client":      "2WEI Music",
    "YouTube URL": "https://www.youtube.com/watch?v=Wiup_C6oIYU",
    "Title":       "Capulet - Summer 2018"
}, {
    "ID":          10,
    "Client":      "2WEI Music",
    "YouTube URL": "https://www.youtube.com/watch?v=FDc9VS5UIAY",
    "Title":       "Hyundai - Santa Fe"
}, {
    "ID":          3,
    "Client":      "2WEI Music",
    "YouTube URL": "https://www.youtube.com/watch?v=K1I7pLVZgWA",
    "Title":       "Popchips - Nutter"
}, {
    "ID":          4,
    "Client":      "2WEI Music",
    "YouTube URL": "https://www.youtube.com/watch?v=lZdrznxHNmU",
    "Title":       "Lufthansa - Lofoten"
}, {
    "ID":          11,
    "Client":      "2WEI Music",
    "YouTube URL": "https://www.youtube.com/watch?v=uLZfQjUw7xs",
    "Thumbnail":   [{
        "id":         "attMq5jHG3nC1KUin",
        "url":        "https://dl.airtable.com/KHPZ11CFThx2HzRWCztW_maxresdefault.jpg",
        "filename":   "maxresdefault.jpg",
        "size":       50923,
        "type":       "image/jpeg",
        "thumbnails": {
            "small": {
                "url":    "https://dl.airtable.com/XK2vXtYEQHCdhadStkNm_small_maxresdefault.jpg",
                "width":  64,
                "height": 36
            },
            "large": {
                "url":    "https://dl.airtable.com/skRlb27T16GNkd8HlkMb_large_maxresdefault.jpg",
                "width":  910,
                "height": 512
            },
            "full":  {
                "url":    "https://dl.airtable.com/hCOfzidMQg66VffZcZzn_full_maxresdefault.jpg",
                "width":  1280,
                "height": 720
            }
        }
    }],
    "Title":       "J\u00e4germeister x Snoop Dogg"
}, {
    "ID":          7,
    "Client":      "2WEI Music",
    "YouTube URL": "https://www.youtube.com/watch?v=_F5K3nmYm8I",
    "Title":       "McDonalds - Oster Countdown"
}, {
    "ID":          1,
    "YouTube URL": "https://www.youtube.com/watch?v=6HwAazEI3DM",
    "Title":       "Ryse - Routine"
}, {"ID": 13}, {
    "ID":          8,
    "Client":      "2WEI Music",
    "YouTube URL": "https://www.youtube.com/watch?v=mFRhuZgLnjE",
    "Title":       "Huk24 - Auto Einfach Einfach Versichern"
}];