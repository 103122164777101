import {Container} from 'unstated';

export default class MenuState extends Container {

    state = {
        showing: false,
    };

    show() {
        this.setState({showing: true});
    }

    hide() {
        this.setState({showing: false});
    }

    toggle() {
        this.setState({showing: !this.state.showing});
    }

}