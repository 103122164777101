import React from 'react';
import ScrollTo from 'react-scroll-to-element';

import Section from './../Section';
import ScrollDowns from './../ScrollDowns';
import Gradient from './../Gradient';
import keysAbove from './../../keys_above_dark.jpg';

export default () => <React.Fragment>

    <Gradient points={{'0%': 0, '100%': .5}} style={{
        position: 'absolute',
        top:      'calc(100vh - 6rem)',
        width:    '100%',
    }}/>

    <div style={{
        position:   'relative',
        left:       0,
        top:        0,
        minWidth:   '100vw',
        background: '#222',
    }}>

        <div style={{
            backgroundImage:    `url(${keysAbove})`,
            backgroundPosition: 'center',
            backgroundSize:     'cover',
            width:              '100%',
            height:             '100%',
            position:           'absolute',
            top:                0,
            left:               0,
        }}/>

        <Section anchor={'about'} className={'scroll-snap'} style={{
            flexDirection: 'column',
            height:        '100vh',
            padding:       '3rem 0 4rem',
        }}>
            <div style={{
                marginTop:  'auto',
                color:      'white',
                textAlign:  'center',
                fontFamily: 'Times, "Times Roman", "Times New Roman", serif',
                fontStyle:  'italic',
                fontWeight: 'lighter',
                fontSize:   '130%',
                padding:    '0 5%',
                zIndex:     1,
            }} className={'smooth'}>
                <p>Hi, I'm <span style={{
                    fontWeight: 'bolder',
                }}>Ruben</span>, I'm a composer. <br/> I make music for commercials and trailers.</p>
                <p>Nice to meet you.</p>
            </div>
            <ScrollTo type={'id'} element={'portfolio'}>
                <ScrollDowns style={{
                    marginTop:     'auto',
                    marginBottom:  '-2rem',
                    cursor:        'pointer',
                    paddingBottom: '2rem',
                    zIndex:        1,
                }} arrow={true}>
                    <span style={{
                        fontFamily:    'Times, "Times Roman", "Times New Roman", serif',
                        fontWeight:    'lighter',
                        color:         'white',
                        marginBottom:  '1.5rem',
                        display:       'block',
                        textTransform: 'uppercase',
                        textAlign:     'center',
                        letterSpacing: 5,
                        // fontStyle:     'italic',
                    }}>Portfolio</span>
                </ScrollDowns>
            </ScrollTo>
        </Section>
    </div>
</React.Fragment>;
