import React from 'react';
import {map} from 'lodash';

import ruben from './../../ruben-profile3.jpg';
import TrackWindow from '../../common/TrackWindow';
import Section from './../Section';
import HoverButton from '../HoverButton';
import {FixedParallaxLayer} from '../ParallaxLayer';
import {WithScrollOffset} from "../ScrollMonitor";

import './contact.css';

export default () => <WithScrollOffset>{offset => {

    const visible = offset > 2;

    return <div id={'contact'} className={'scroll-snap'}>
        <div style={{height: '100vh'}}/>
        {visible ? <FixedParallaxLayer style={{zIndex: 1}}>
            <TrackWindow>
                {({width}) => {

                    const wrap = width < 700;

                    return <Section style={{
                        padding:            '3rem',
                        background:         '#111',
                        flexDirection:      'column',
                        backgroundPosition: 'center',
                        backgroundSize:     'cover',
                        width:              '100%',
                        height:             '100%',
                    }}>

                        <h1 style={{
                            marginTop:     'auto',
                            color:         'white',
                            lineHeight:    1.4,
                            letterSpacing: 6,
                            fontSize:      wrap ? '140%' : '200%',
                            textTransform: 'uppercase',
                            fontFamily:    'Times, "Times Roman", "Times New Roman", serif',
                            fontWeight:    'lighter',
                        }} className={'smooth'}>Get in touch</h1>

                        <div style={{
                            maxWidth:      640,
                            width:         '80%',
                            display:       'flex',
                            flexDirection: wrap ? 'column' : 'row',
                            padding:       '2rem',
                            alignItems:    'center',
                            color:         'white',
                        }} className={'contact-inner-block'}>
                            <img src={ruben} alt={'Ruben Norg portret'}
                                 className={'ruben-portret'}
                                 style={{
                                     width:  wrap ? 160 : 200,
                                     height: wrap ? 160 : 200,
                                     zIndex: 100,
                                     border: '2px solid white',
                                 }}/>
                            <div style={{
                                marginLeft: wrap ? 0 : '3rem',
                                marginTop:  wrap ? '2rem' : 0,
                                textAlign:  wrap ? 'center' : 'right',
                                flexGrow:   1,
                                fontSize:   wrap ? '120%' : '190%',
                                fontFamily: 'Avenir Next, sans-serif',
                                fontWeight: 100,
                            }}>
                                <p style={{margin: '.4rem 0'}}>
                                    <HoverButton
                                        style={{
                                            textAlign:          'center',
                                            textTransform:      'none',
                                            marginBottom:       '.4rem',
                                            'WebkitTextStroke': '.5px white',
                                        }}
                                        href={'mailto:info@rubennorg.com'}
                                    >info@rubennorg.com</HoverButton>
                                </p>
                                <p style={{
                                    margin:             '-.6rem 0 .4rem',
                                    color:              '#a0a0a0',
                                    'WebkitTextStroke': '.5px #a0a0a0',
                                }}>+31 6 11500936</p>
                            </div>
                        </div>
                        <ul style={{
                            listStyleType: 'none',
                            padding:       0,
                            display:       'flex',
                            flexDirection: 'row',
                            marginBottom:  'auto',
                        }}>
                            {map({
                                instagram:  'https://instagram.com/rubennorgproductions',
                                facebook:   'https://facebook.com/rubennorgproductions',
                                soundcloud: 'https://soundcloud.com/rubennorgproductions',
                            }, (url, platform) => {
                                return <li key={platform} style={{
                                    margin: wrap ? '.5rem .5rem 3rem' : '1rem 1rem 3rem',
                                }}>
                                    <a href={url} target={'_blank'} style={{
                                        color:          '#a0a0a0',
                                        textTransform:  'uppercase',
                                        letterSpacing:  2,
                                        fontSize:       wrap ? '65%' : '80%',
                                        textDecoration: 'none',
                                        fontWeight:     'lighter',
                                    }}>{platform}</a>
                                </li>
                            })}
                        </ul>
                    </Section>;
                }}
            </TrackWindow>
        </FixedParallaxLayer> : null}
    </div>;
}}</WithScrollOffset>;
