import React from 'react';
import Fade from 'react-reveal/Fade';
import ScrollTo from 'react-scroll-to-element';

import rnp from '../../rnp.svg';

import poster from './../../video-poster.jpg';

import Section from './../Section';
import VideoBg from './../VideoBg';
import ScrollDowns from './../ScrollDowns';
import TrackWindow from '../../common/TrackWindow';

import MeasureElement from '../../common/MeasureElement';

import {FixedParallaxLayer} from '../ParallaxLayer';

import {WithScrollOffset} from '../ScrollMonitor';

const Splash = ({visible}) => <div style={{
    position:   'relative',
    left:       0,
    top:        0,
    minWidth:   '100vw',
    background: '#222',
}} className={'scroll-snap'}>

    <div style={{height: '100vh'}}/>

    <FixedParallaxLayer style={{opacity: visible ? 1 : 0}}>
        <MeasureElement>{(dimensions) => {
            return <VideoBg
                source="/video/splash.mp4"
                poster={poster}
                dimensions={dimensions}
            />;
        }}</MeasureElement>
    </FixedParallaxLayer>

    <FixedParallaxLayer style={{opacity: visible ? 1 : 0}}>
        <Section anchor={'splash'} style={{
            height: '100vh',
            color:  'white',
        }}>
            <TrackWindow>{({width}) => {
                const defaultLogoWidth = 450;
                const defaultFontSize  = 19.5;
                const minFontSize      = 14;
                const scaleFactor      = Math.min(1, width / 780);
                return <Fade duration={2000}>
                    <img src={rnp} alt={'RNP Logo'} style={{
                        width:      defaultLogoWidth * scaleFactor,
                        maxWidth:   450,
                        marginTop:  'auto',
                        paddingTop: '4rem',
                    }}/>
                    <span style={{
                        fontFamily:    'Times, "Times Roman", "Times New Roman", serif',
                        textTransform: 'uppercase',
                        textAlign:     'center',
                        display:       'block',
                        marginTop:     '2rem',
                        fontSize:      Math.max(defaultFontSize * scaleFactor, minFontSize),
                        letterSpacing: 3,
                    }} className={'smooth'}>
                        Ruben Norg Productions
                    </span>
                </Fade>;
            }}</TrackWindow>
            <ScrollTo type={'id'} element={'about'}>
                <ScrollDowns className={'top-scroller'} style={{
                    margin: 'auto 2rem 2rem',
                    cursor: 'pointer',
                }}/>
            </ScrollTo>
        </Section>
    </FixedParallaxLayer>

</div>;

export default () => <WithScrollOffset>{offset => {

    const visible = offset < 1.5;

    return <Splash {...{visible}}/>;

}}</WithScrollOffset>;