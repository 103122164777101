import React from 'react';
import {Subscribe} from 'unstated';
import Transition from 'react-transition-group/Transition';
import ScrollTo from 'react-scroll-to-element';

import './menu.css';

import MenuState from './../state/MenuState';

const duration = 350;

const defaultStyle = {
    transition: `opacity ${duration}ms`,
    opacity:    0,
};

const transitionStyles = {
    entering: {opacity: 1},
    entered:  {opacity: 1},
};

export const MenuItem = ({anchor, ...props}) => <Subscribe to={[MenuState]}>{menu => (
    <ScrollTo type={'id'} element={anchor}>
        <div>
            <a onClick={() => menu.hide()} className={'menu-item smooth'} style={{
                color:         'white',
                textTransform: 'uppercase',
                padding:       '.4rem',
                margin:        '1vh',
                cursor:        'pointer',
                display:       'block',
                fontSize:      25,
                letterSpacing: 6,
                fontFamily:    'Times, "Times Roman", "Times New Roman", serif',
            }}>
                <span {...props}/>
            </a>
        </div>
    </ScrollTo>
)}</Subscribe>;

export const Menu = ({items}) => <div
    style={{
        display:       'flex',
        flexDirection: 'column',
        alignItems:    'center',
    }}
>{Object.keys(items).map((anchor) => (
    <MenuItem anchor={anchor} key={anchor}>{items[anchor]}</MenuItem>
))}</div>;

export default () => <Subscribe to={[MenuState]}>{menu => <Transition in={menu.state.showing} timeout={duration}>
    {(state) => (
        (state !== 'FALSEexited') && <div style={{
            ...defaultStyle,
            ...transitionStyles[state],
            position:       'fixed',
            top:            0,
            left:           0,
            width:          '100%',
            height:         '100%',
            background:     'rgba(35,35,35, .9)',
            display:        'flex',
            flexDirection:  'column',
            alignItems:     'center',
            justifyContent: 'center',
            pointerEvents:  state === 'exited' ? 'none' : null,
            zIndex:         2,
        }}>
            <Menu items={{
                about:     'About me',
                portfolio: 'Portfolio',
                contact:   'Contact',
            }}/>
        </div>
    )}
</Transition>}</Subscribe>;