import React from 'react';

import {Provider} from 'unstated';
import {ParallaxProvider} from 'react-scroll-parallax';

import Splash from './components/sections/Splash';
import About from './components/sections/About';
import Portfolio from './components/sections/Portfolio';
import Contact from './components/sections/Contact';

import Hamburger from './components/Hamburger';
import Menu from './components/Menu';

import {ScrollMonitor} from './components/ScrollMonitor';

const App = () => <Provider><ParallaxProvider><ScrollMonitor>

    <Splash/>
    <About/>
    <Portfolio/>
    <Contact/>

    <Hamburger/>
    <Menu/>

</ScrollMonitor></ParallaxProvider></Provider>;

export default App;
