import React from 'react';
import {Subscribe} from 'unstated';

import './hamburger.css';

import MenuState from './../state/MenuState';

export default () => (
    <Subscribe to={[MenuState]}>{menu => <div
        className={'hamburger-topright'}
        style={{
            position: 'fixed',
            zIndex:   999,
        }}
    >
        <a
            id="hamburger-icon"
            title="Menu"
            className={menu.state.showing ? 'active' : ''}
            onClick={() => menu.toggle()}
        >
            <span className="line line-1"></span>
            <span className="line line-2"></span>
            <span className="line line-3"></span>
        </a>
    </div>
    }</Subscribe>
);