import React from 'react';
import ReactDOM from 'react-dom';

import $ from 'jquery';

// import {throttle} from 'lodash';

const ScrollContext = React.createContext();

export class ScrollMonitor extends React.Component {

    constructor() {
        super();
        this.state = {
            currentY:    null,
            currentRelY: null,
        };
    }

    componentDidMount() {
        // this._listener = throttle(() => this.update(), 60);
        this._listener = () => this.update();
        document.addEventListener('scroll', this._listener);
        this.ownNode = ReactDOM.findDOMNode(this);
        // this.ownOffsetTop = $(this.ownNode).offset().top;
        // console.log({ownNode: this.ownNode, ownOffsetTop: this.ownOffsetTop});
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this._listener);
    }

    update() {
        const scrollTop    = $(window).scrollTop();
        const windowHeight = window.innerHeight;
        // const ownOffsetTop = $(this.ownNode).offset().top;
        // const maxScroll    = document.body.scrollHeight - windowHeight;
        // const scrollTopPadded = scrollTop / maxScroll * (maxScroll + (this.props.scrollPadding || 0));
        // const weights         = mapValues(this.sections, section => {
        //     const offset = $(section).offset();
        //     const top    = offset.top - scrollTopPadded;
        //     const bottom = (offset.top + $(section).outerHeight()) - scrollTopPadded;
        //     return partWeight(top / windowHeight, bottom / windowHeight);
        // });
        // const currentSection  = maxBy(keys(weights), id => weights[id]);
        this.setState({
            currentY: scrollTop,
            windowHeight,
            // ownOffsetTop,
        });
    }

    // registerSection(section) {
    //     this.sections[section.props.id] = ReactDOM.findDOMNode(section);
    //     this._listener();
    // }

    // unregisterSection(section) {
    //     delete this.sections[section.props.id];
    //     this._listener();
    // }

    render() {
        return <ScrollContext.Provider value={{
            currentY:     this.state.currentY,
            currentRelY:  this.state.currentRelY,
            ownOffsetTop: this.state.ownOffsetTop,
            windowHeight: this.state.windowHeight,
        }}>{this.props.children}</ScrollContext.Provider>;
    }
}

export const ScrollConsumer = ScrollContext.Consumer;

export const WithScrollOffset = ({

    ratio = true,
    // relative = false,
    children

}) => <ScrollConsumer>{scrollContext => {

    // console.log(scrollContext);

    let val = scrollContext.currentY;
    // if (relative) val -= scrollContext.ownOffsetTop;
    if (ratio) val /= scrollContext.windowHeight;

    // console.log(scrollContext);

    return children(val || 0);

}}</ScrollConsumer>;
