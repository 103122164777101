import axios from 'axios';
import getYoutubeId from 'get-youtube-id';
import {orderBy} from 'lodash';

import fallbackPortfolio from './fallbackPortfolio';

export const getSourceData = async () => {
    try {
        const response = await axios.get('/api');
        return response.data;
    } catch (e) {
        console.warn('Couldn\'t fetch / process live portfolio', e);
        return fallbackPortfolio;
    }
};

export default async () => {
    const data = await getSourceData();
    return orderBy(data, ['ID'], ['desc'])
        .map(({
            Title:         title,
            'YouTube URL': url,
            Client:        client,
            Thumbnail:     thumbnail,
        }) => {
            const yt = getYoutubeId(url);
            return {title, yt, client, thumbnail};
        })
        .filter(({yt}) => yt);
};