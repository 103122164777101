import React from 'react';
import Fade from 'react-reveal/Fade';
import Modal from 'react-modal';
import Youtube from 'react-youtube';

import {chunk, get} from 'lodash';

import getPortfolio from '../../data/getPortfolio';

import TrackWindow from '../../common/TrackWindow';

import Section from '../Section';

import './portfolio.css';

export const getYtThumbnailById = id => `https://img.youtube.com/vi/${id}/maxresdefault.jpg`;

export const getThumbnailUrl = item => {
    return get(item, 'thumbnail.0.thumbnails.large.url', getYtThumbnailById(item.yt));
};

export const PortfolioItem = ({
    title,
    client = null,
    yt, thumbnail,
    openModal,
    width = .3333,
}) => <Fade>

    <div
        style={{
            cursor:             'pointer',
            backgroundImage:    `url(${getThumbnailUrl({yt, thumbnail})})`,
            backgroundSize:     'cover',
            backgroundPosition: 'center',
            width:              (width * 100) + '%',
            height:             (width * 100 * (9 / 16)) + 'vw',
            border:             '1px solid white',
            borderWidth:        '1px 1px 0 0',
            position:           'relative',
        }}
        className={'portfolio-item'}
        onClick={openModal}
    >

        <div style={{
            position:       'absolute',
            width:          '100%',
            height:         '100%',
            background:     'rgba(0,0,0,0.7)',
            display:        'flex',
            flexDirection:  'column',
            justifyContent: 'center',
            alignItems:     'center',
            color:          'white',
            opacity:        0,
            transition:     'opacity .2s',
            border:         '1px solid white',
            boxSizing:      'border-box',
        }}>
            <h4 style={{
                fontFamily:    'Times, "Times Roman", "Times New Roman", serif',
                fontWeight:    'normal',
                textTransform: 'uppercase',
                fontSize:      title.length > 32 ? '100%' : '105%',
                margin:        '.2rem .6rem',
                textAlign:     'center',
                textShadow:    '0 0 4px rgba(0,0,0,0.8)',
            }} className={'smooth'}>{title}</h4>
            {client ? <span style={{
                display:       'block',
                textTransform: 'uppercase',
                fontSize:      '80%',
                marginBottom:  '.4rem',
                textShadow:    '0 0 4px rgba(0,0,0,0.8)',
            }}>
                For <strong>{client}</strong>
            </span> : null}
        </div>
    </div>
</Fade>;

export const PortfolioRowRemainder = () => <div style={{
    textTransform:  'uppercase',
    fontFamily:     'Times, "Times Roman", "Times New Roman", serif',
    flex:           1,
    display:        'flex',
    alignItems:     'center',
    justifyContent: 'center',
    letterSpacing:  5,
}}>
    More coming soon
</div>;

export const PortfolioRow = ({children, columns = 1, openModal}) => <div style={{
    overflowX: 'hidden',
}}>
    <div className='portfolio-row' style={{
        // overflowX: 'hidden',
        display: 'flex',
        width:   '102%',
    }}>
        {children.map((item, i) => <PortfolioItem
            key={i}
            width={Math.floor(.98 / columns * 1000) / 1000}
            openModal={() => openModal(item.yt)}
            {...item}
        />)}
        {(columns - children.length) && <PortfolioRowRemainder/>}
    </div>
</div>;

export default class Portfolio extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items:            [],
            currentlyViewing: null,
        };
    }

    async componentDidMount() {
        this.setState({
            items: await getPortfolio(),
        });
    }

    setCurrentlyViewing(id) {
        this.setState({
            currentlyViewing: id,
        });
    }

    closeModal() {
        this.setState({
            currentlyViewing: null,
        });
    }

    render() {
        return <TrackWindow>
            {({width}) => {

                const goalItemWidth  = 300;
                const maxItemsPerRow = 3;
                const columns        = Math.min(Math.floor(width / goalItemWidth), maxItemsPerRow);

                return <Section className={'scroll-snap'} anchor={'portfolio'} style={{
                    background:     'white',
                    padding:        0,
                    justifyContent: null,
                    // borderBottom:   '1px solid white',
                }}>

                    {this.state.currentlyViewing && <div style={{
                        position: 'fixed',
                        color:    'white',
                        zIndex:   10001
                    }} className={'modal-close-button'}>
                        <button onClick={() => this.closeModal()} style={{
                            textTransform: 'uppercase',
                            width:         60,
                            height:        60,
                            background:    'transparent',
                            color:         'white',
                            border:        'none',
                            textAlign:     'center',
                            cursor:        'pointer',
                            fontSize:      '120%',
                            outline:       'none',
                        }}>
                            <div className='close thick' aria-label="close"/>
                        </button>
                    </div>}

                    <TrackWindow>{({width, height}) => {

                        const maxProportion = .8;
                        const proportion    = 16 / 9;
                        const maxWidth      = width * maxProportion;
                        const maxHeight     = height * maxProportion;
                        const mHeight       = Math.min(maxHeight, maxWidth / proportion);
                        const mWidth        = Math.min(maxWidth, maxHeight * proportion);

                        return <Modal
                            isOpen={!!this.state.currentlyViewing}
                            onRequestClose={() => this.closeModal()}
                            contentLabel="Video"
                            style={{
                                content: {
                                    padding: 0,
                                    border:  'none',
                                    zIndex:  10000,
                                    width:   mWidth,
                                    height:  mHeight,
                                }
                            }}
                        >
                            {this.state.currentlyViewing ? <Youtube
                                videoId={this.state.currentlyViewing}
                                opts={{
                                    width:      '100%',
                                    height:     '100%',
                                    playerVars: {
                                        autoplay: 1,
                                        rel:      0,
                                        showinfo: 0,
                                    }
                                }}
                            /> : null}
                        </Modal>;
                    }}</TrackWindow>

                    {this.state.items.length ? <div style={{
                        width:      '100%',
                        zIndex:     1,
                        background: 'white',
                    }}>
                        {chunk(this.state.items, columns).map((items, i) => {
                            return <PortfolioRow key={i} columns={columns} openModal={yt => {
                                return this.setCurrentlyViewing(yt);
                            }}>{items}</PortfolioRow>
                        })}
                    </div> : <p>Loading portfolio...</p>}

                    <div style={{width: '100%', height: 1, position: 'relative'}}>
                        <div style={{
                            width:      '100%',
                            height:     1,
                            background: 'white',
                            position:   'absolute',
                            zIndex:     1,
                        }}/>
                    </div>

                </Section>;
            }}
        </TrackWindow>;
    }
}