import React from 'react';
import VideoPlayer from 'react-background-video-player';

export default ({source, poster = null, dimensions: {width = null, height = null} = {}}) => {

    if (!width || !height) return null;

    return <div style={{
        position: 'absolute',
        width, height,
        ...(poster ? {
            backgroundImage:    `url(${poster})`,
            backgroundSize:     'cover',
            backgroundPosition: 'center center',
        } : {})
    }}>
        <VideoPlayer
            containerWidth={width}
            containerHeight={height}
            src={source}
            poster={poster}
        />
    </div>;
};
