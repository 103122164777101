import React from 'react';

import $ from 'jquery';

export default class MeasureElement extends React.Component {

    constructor(props) {
        super(props);
        const initialWidth  = props.width || $(window).width();
        const initialHeight = props.height || $(window).height();
        this.wrap           = React.createRef();
        this.lastRef        = null;
        this.state          = {
            width:  initialWidth,
            height: initialHeight,
        };
    }

    update() {
        if (this.wrap.current !== this.lastRef) {
            this.lastRef = this.wrap.current;
            // this.updateDimensions();
        }
    }

    updateDimensions() {
        if (this.wrap.current) {
            const $wrap = $(this.wrap.current);
            this.setState({
                width:  $wrap.outerWidth(),
                height: $wrap.outerHeight(),
            })
        }
    }

    componentDidMount() {
        this.update();
        this.updateDimensions();
        this.interval = setInterval(() => {
            this.update();
            this.updateDimensions();
        }, 100);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    // componentDidUpdate() {
    //     this.update();
    // }

    render() {
        return <div ref={this.wrap} style={{
            width:  '100%',
            height: '100%',
        }}>{this.props.children(this.state)}</div>;
    }
}