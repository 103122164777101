import React from 'react';

import './scrollDowns.css';

import {WithScrollOffset} from "./ScrollMonitor";

export const ArrowDown = ({color = 'white'}) => <div className={"animated-arrow"}>
    <div style={{
        borderRadius:    2,
        width:           31,
        height:          31,
        border:          `2px solid ${color}`,
        transform:       'rotate(45deg)',
        borderLeftColor: 'transparent',
        borderTopColor:  'transparent',
        margin:          '0 auto',
    }}/>
</div>;

export default ({

    color = 'white',
    arrow = false,
    children,
    ...props

}) => {

    return <WithScrollOffset>{offset => {
        return <div className="scroll-downs" {...props}>
            {children ? children :
                <div className="mousey" style={{
                    opacity: Math.max(1 - offset * 1.4, 0),
                }}>
                    <div className="scroller"/>
                </div>}
            {arrow ? <ArrowDown color={color}/> : null}
        </div>;
    }}</WithScrollOffset>;
};