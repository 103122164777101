import React from 'react';

export default ({

    anchor = null,
    style = {},

    ...props,

} = {}) => <div id={anchor} className={'scroll-snap'} style={{

    display:        'flex',
    flexDirection:  'column',
    justifyContent: 'center',
    alignItems:     'center',
    minHeight:      '100vh',
    boxSizing:      'border-box',

    ...style,

}} {...props}/>;