import React from "react";
import $ from "jquery";

class TrackWindow extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.window  = window;
        this.$window = $(window);
        this.state   = this.getCurrent();
    }

    getCurrent() {
        return {
            width:  this.$window.width(),
            height: this.window.innerHeight ? this.window.innerHeight : this.$window.height(),
        };
    }

    componentWillMount() {
        this.listener = () => this.setState(this.getCurrent());
        this.$window.on('resize', this.listener);
    }

    componentWillUnmount() {
        this.$window.off('resize', this.listener);
    }

    render() {
        return this.props.children(this.state);
    }
}

export default TrackWindow;